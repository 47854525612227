import { reactive, ref } from "vue";
import { EditOrderTemplate, GetOrderCompList, GetOrdetTmpDetail, PostOrderTemplate } from '@/api/OrderInfoApi';
import { ElMessage } from "element-plus";
import { ModuleType } from "@/common/enum";
export default function (props, emit) {
    const visible = ref(true);
    const name = ref('');
    const remarks = ref('');
    const btnLoading = ref(false);
    const waitList = reactive(new Array());
    const selectededList = reactive(new Array());
    // const createTemplateModule = (id: number, desc: string, mname: string, mrule: string, mType: number, name: string, prompt: string): TemplateModule => {
    //     const temp = new TemplateModule();
    //     temp.id = id;
    //     temp.moduleDesc = desc;
    //     temp.moduleName = mname;
    //     temp.moduleRule = mrule;
    //     temp.moduleType = mType;
    //     temp.prompt = prompt;
    //     temp.name = name;
    //     return temp;
    // }
    // waitList.push(createTemplateModule(1, "支持填中文、英文、数字，不支持符号，10个字符以内", "姓名组件", '^[\\\\u4e00-\\\\u9fa5a-zA-Z0-9]{1,10}$', 1, "收件人", "请输入收件人姓名"));
    // waitList.push(createTemplateModule(2, "仅支持数字，长度不超过11位", "手机号组件", '^1[3456789]\\\\d{9}$', 1, "联系电话", "请输入联系电话"));
    // waitList.push(createTemplateModule(3, "支持填中文、英文、数字，_，100个字符以内", "地址组件", '^[\\\\u4e00-\\\\u9fa5_a-zA-Z0-9]{1,100}$', 2, "收货地址", "请输入收货地址"));
    const placeholderText = (item) => {
        const name = item.moduleName?.slice(0, -2);
        let prompt = '请输入' + item.moduleName?.slice(0, -2);
        if (item.moduleType == ModuleType.Text) {
            prompt = prompt + '，100字以内';
        }
        if (item.moduleType == ModuleType.Image) {
            prompt = '请上传' + name + '，不超过8MB';
        }
        if (item.moduleType == ModuleType.Address) {
            prompt = '请输入详细地址';
        }
        return {
            name,
            prompt,
        };
    };
    const handleSelect = (item) => {
        selectededList.push({ ...item, createTime: new Date().getTime(), name: placeholderText(item).name, prompt: placeholderText(item).prompt });
    };
    const handleDel = (index) => {
        selectededList.splice(index, 1);
    };
    const dragIndex = ref(0);
    const dragstart = (index) => {
        dragIndex.value = index;
    };
    const dragenter = (e, index) => {
        e.preventDefault();
        if (dragIndex.value !== index) {
            const moving = selectededList[dragIndex.value];
            selectededList.splice(dragIndex.value, 1);
            selectededList.splice(index, 0, moving);
            dragIndex.value = index;
        }
    };
    const dragover = (e) => {
        e.preventDefault();
    };
    class errObj {
    }
    const errList = reactive(new Array());
    const hasEmpty = (index, key) => {
        for (const idx in errList) {
            if (errList[idx].index == index && errList[idx].key == key) {
                return true;
            }
        }
        return false;
    };
    const getTmpDetail = () => {
        selectededList.splice(0, selectededList.length);
        const temptime = new Date().getTime();
        GetOrdetTmpDetail({ id: props.id }).then((res) => {
            res.resultData.content.forEach((i, index) => {
                i.createTime = temptime - index;
                selectededList.push(i);
            });
            name.value = res.resultData.name;
            remarks.value = res.resultData.remarks;
        });
    };
    const createOrderTemplate = () => {
        btnLoading.value = true;
        PostOrderTemplate({
            content: selectededList,
            name: name.value,
            remarks: remarks.value,
        })
            .then((res) => {
            btnLoading.value = false;
            ElMessage.success('操作成功');
            emit('success');
        })
            .catch(() => {
            btnLoading.value = false;
        });
    };
    const editOrderTemplate = () => {
        btnLoading.value = true;
        EditOrderTemplate({
            id: props.id,
            content: selectededList,
            name: name.value,
            remarks: remarks.value,
        })
            .then((res) => {
            btnLoading.value = false;
            ElMessage.success('操作成功');
            emit('success');
        })
            .catch(() => {
            btnLoading.value = false;
        });
    };
    const getCompList = () => {
        waitList.splice(0, waitList.length);
        GetOrderCompList('').then((res) => {
            res.resultData.forEach((i) => {
                waitList.push(i);
            });
            console.log();
        });
    };
    return {
        name,
        remarks,
        visible,
        waitList,
        selectededList,
        errList,
        btnLoading,
        hasEmpty,
        handleSelect,
        handleDel,
        dragstart,
        dragenter,
        dragover,
        getTmpDetail,
        createOrderTemplate,
        editOrderTemplate,
        getCompList,
    };
}
