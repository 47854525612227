import { GetOrderTemplateList } from "@/api/OrderInfoApi";
import { reactive, ref } from "vue";
export default function () {
    const pageSize = ref(20);
    const total = ref(0);
    const showDetailDlg = ref(false);
    const tableLoading = ref(false);
    const editTmpId = ref(null);
    const params = reactive({
        createdTimeStart: '',
        createdTimeEnd: '',
        name: "",
        createTime: []
    });
    const page = ref(1);
    const orderTemplateData = reactive(new Array());
    const queryData = () => {
        orderTemplateData.splice(0, orderTemplateData.length);
        tableLoading.value = true;
        GetOrderTemplateList({
            createdTimeEnd: params.createdTimeEnd,
            createdTimeStart: params.createdTimeStart,
            name: params.name,
            pageNum: page.value,
            pageSize: pageSize.value,
        }).then((res) => {
            tableLoading.value = false;
            total.value = res.resultData.total;
            res.resultData.list.forEach((i) => {
                orderTemplateData.push(i);
            });
        }).catch(() => {
            tableLoading.value = false;
        });
    };
    const createTemplate = () => {
        showDetailDlg.value = true;
        editTmpId.value = null;
    };
    const onDetailDlgClose = () => {
        showDetailDlg.value = false;
        queryData();
    };
    const handleSizeChange = (val) => {
        pageSize.value = val;
        queryData();
    };
    const handleCurrentChange = (val) => {
        page.value = val;
        queryData();
    };
    const onDetailDlgSubmit = () => {
        showDetailDlg.value = false;
        queryData();
    };
    const handleChangeTime = (val) => {
        if (val && val.length > 0) {
            params.createdTimeStart = val[0];
            params.createdTimeEnd = val[1];
        }
        else {
            params.createdTimeStart = '';
            params.createdTimeEnd = '';
        }
    };
    return {
        pageSize,
        total,
        page,
        params,
        showDetailDlg,
        orderTemplateData,
        tableLoading,
        editTmpId,
        queryData,
        createTemplate,
        onDetailDlgClose,
        handleSizeChange,
        handleCurrentChange,
        onDetailDlgSubmit,
        handleChangeTime,
    };
}
