import { BreadCrumb } from '@/services/BreadCrumb/BreadCrumb';
import BreadCrumbService from '@/services/BreadCrumb/BreadCrumbService';
import { defineComponent, onMounted } from 'vue';
import OrderInfoFunc from './Index';
import DetailDlg from './Detail/Index.vue';
export default defineComponent({
    components: {
        DetailDlg,
    },
    setup() {
        onMounted(() => {
            BreadCrumbService.ClearBreadCrumb();
            BreadCrumbService.InsertBreadCrumb(new BreadCrumb('orderinfo', '', '下单设置'));
            queryData();
        });
        const { pageSize, page, total, params, showDetailDlg, orderTemplateData, tableLoading, editTmpId, queryData, createTemplate, onDetailDlgClose, handleSizeChange, handleCurrentChange, onDetailDlgSubmit, handleChangeTime, } = OrderInfoFunc();
        return {
            pageSize,
            total,
            page,
            params,
            showDetailDlg,
            orderTemplateData,
            tableLoading,
            editTmpId,
            queryData,
            createTemplate,
            onDetailDlgClose,
            handleSizeChange,
            handleCurrentChange,
            onDetailDlgSubmit,
            handleChangeTime,
        };
    },
});
