import { ElMessage } from 'element-plus';
import { defineComponent, onMounted } from 'vue';
import DetailFunc from './Index';
export default defineComponent({
    props: {
        isReadonly: Boolean,
        id: Number,
    },
    emits: ['close', 'success'],
    setup(props, { emit }) {
        const { name, remarks, visible, waitList, selectededList, errList, btnLoading, hasEmpty, handleSelect, handleDel, dragstart, dragenter, dragover, getTmpDetail, createOrderTemplate, editOrderTemplate, getCompList, } = DetailFunc(props, emit);
        onMounted(() => {
            getCompList();
            if (props.id) {
                getTmpDetail();
            }
        });
        const onClose = () => {
            emit('close');
        };
        const onSubmit = () => {
            if (selectededList.length == 0) {
                ElMessage.warning('您还没有选入组件');
                return;
            }
            errList.splice(0, errList.length);
            if (!name.value) {
                ElMessage.warning('模板名称不能为空');
                return;
            }
            selectededList.map((i, index) => {
                if (!i.name) {
                    errList.push({ index, key: 'name' });
                }
                if (!i.prompt) {
                    errList.push({ index, key: 'prompt' });
                }
            });
            if (errList.length > 0) {
                ElMessage.warning('请先补全填写项名称或填写项提示文案');
                return;
            }
            if (props.id) {
                editOrderTemplate();
            }
            else {
                createOrderTemplate();
            }
        };
        return {
            name,
            remarks,
            visible,
            waitList,
            selectededList,
            btnLoading,
            onClose,
            handleSelect,
            handleDel,
            dragenter,
            dragover,
            dragstart,
            onSubmit,
            hasEmpty,
            getTmpDetail,
        };
    },
});
